<template>
  <div class="about">
    <h1>Über uns</h1>
    <VueTitle title="Über uns"></VueTitle>
    <div class="card">
      <h3>40 Jahre Tennisclub Dörverden e.V.</h3>
      <p>
        Der Tennisclub Dörverden e.V. feierte in 2023 sein 40-jähriges Jubiläum.
        <br/>
        Im Jahre 1983 haben tennisbegeisterte Einwohner den Verein gegründet. Neben den beiden Tennisplätzen, die im Jahre 1985 fertiggestellt wurden, konnte im Jahre 1987 auch das Vereinshaus eingeweiht werden. Die Arbeiten auf dem vereinseigenen Gelände wurden überwiegend in Eigenleistung der Mitglieder ausgeführt.
        <br/>
        Der Tennissport wurde im Laufe der Jahre immer beliebter und so wuchs der Verein auf über 150 Mitglieder an. Nach dem Ende des Tennisbooms in Deutschland schrumpfte die Mitgliederzahl wieder.
        <br/>
        Um die Anlage noch attraktiver zu gestalten, wurde sie im Jahre 2014 um einen Bouleplatz erweitert. Seither erfreut sich auch das Boulespiel großer Beliebtheit.
      </p>
      <h3>Aktive Tennisspieler, die im Jahre 2024 dem Verein beitreten, zahlen einmalig für das laufende Jahr keine Mitgliedsbeiträge.</h3>
      <p>
        Der Verein weist außerdem auf die bereits bestehenden Vereinbarungen mit dem TSV Dörverden e.V. und dem SC Weser Barme e.V. hin. Danach zahlen aktive Mitglieder dieser beiden Vereine laufend lediglich die Hälfte eines Jahresbeitrages.
        <br/>
        Ferner besteht eine Kooperationsvereinbarung mit der Tennisabteilung des TSV Jahn Westen e. V. zur Förderung des Tennissports in der Gemeinde Dörverden.
        <br/>
        <br/>
        Ansprechpartner des Vereins sind:
        <ul>
          <li>Für Tennis: Lars Hermann Tel. 0174 3064666 </li>
          <li>Für Boule: Irmgard Kern Tel. 04234 1525 </li>
        </ul>
      </p>
      <h3>Vorstand</h3>
      <p>
        Albert Kinat, 1. Vorsitzender <br />
        Herbert Pagels, Kassenwart
      </p>
    </div>
  </div>
</template>
<script>
import VueTitle from "@/components/VueTitle";
export default {
  components: { VueTitle },
};
</script>

<style scoped>
.about {
  margin: 78px 6% 6%;
}

.card {
  border-left: lightgray 5px solid;
  padding-left: 20px;
}

@media only screen and (min-width: 1050px) {
  .card {
    width: 60vw;
  }
}
</style>
